import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import OneColSection from '../components/OneColSection'
import { Row, Col } from 'react-bootstrap'
import { graphql } from "gatsby"
import styled from 'styled-components'
import { date } from '../components/dateFn'
import StyledLinkButton from '../components/common/StyledLinkButton'
import { Link } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { StyledAccordion } from '../components/common/StyledAccordion'

function NewsPage(props) {
  // 

  // pass some values down the query tree
  for (let index = 0; index < props.data.prismic.allPages.edges.length; index++) {
    const element = props.data.prismic.allPages.edges[index];

    element.node.body[0].primary.pageTitle = element.node.page_title;
    element.node.body[0].primary.slug = element.node._meta.uid;
  }

  const pageTitle = [{ spans: [], type: "heading1", text: "News" }];

  const content = (
    <>
      {props.data.prismic.allPages.edges.map((newsItem, i) => {
        // 
        if (!newsItem.node.body[0].primary.archiving_date) {

          return (
            <Row key={i}>
              <Col md={4}>
                <Link to={`../${newsItem.node.body[0].primary.slug}`}>
                  <StyledImage src={newsItem.node.body[0].primary.image.url} />
                </Link>
              </Col>
              <Col className="mt-2 mt-lg-1" md={2}><h6>{date(newsItem.node.body[0].primary.date_issued)}</h6></Col>
              <Col md={5} className="mb-5 news-col">
                <PrismicRichText
                  field={newsItem.node.body[0].primary.pageTitle}
                  components={{
                    heading1: ({ children }) => <h3 className="heading">{children}</h3>
                  }}
                />
                <p style={{ fontSize: "0.9rem" }}>{newsItem.node.body[0].primary.teaser}</p>
                <StyledLinkButton small={true} to={`../${newsItem.node.body[0].primary.slug}`}>mehr erfahren...</StyledLinkButton>
              </Col>
            </Row>
          )
        } else {
          return null
        }
      })}
    </>
  )
  const archivedContent = (
    <>
      <h3>Archiv</h3>
      <StyledAccordion defaultActiveKey="0">
        {props.data.prismic.allPages.edges.map((newsItem, i) => {
          if (newsItem.node.body[0].primary.archiving_date) {

            return (
              <Card key={i}>
                <Card.Header>
                  <Accordion.Toggle as={Card.Header} variant="link" eventKey={`0 + ${i}`}>
                    <PrismicRichText
                      field={newsItem.node.body[0].primary.pageTitle}
                      components={{
                        heading1: ({ children }) => <h6 className="heading">{children}</h6>
                      }}
                    />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={`0 + ${i}`}>
                  <Card.Body>
                    <p className='news-accordion-body-date'>
                      Veröffentlichungsdatum: {date(newsItem.node.body[0].primary.date_issued)}
                    </p>
                    <p className='news-accordion-body'>
                      {newsItem.node.body[0].primary.teaser}
                    </p>
                    <StyledLinkButton small={true} to={`../${newsItem.node.body[0].primary.slug}`}>mehr erfahren...</StyledLinkButton>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )
          } else {
            return null
          }
        })}
      </StyledAccordion>
    </>
  )

  return (
    <Layout>
      <>
        <PageTitle pageTitle={pageTitle} />
        <OneColSection contentType="component" content={content} />
        <OneColSection contentType="component" content={archivedContent} />
      </>
    </Layout>
  )
}

export default NewsPage

const StyledImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 20px;
`

export const query = graphql`
  {
    prismic {
      allPages(where: {page_type: "News"}, sortBy: meta_firstPublicationDate_ASC, lang: "de-ch") {
        edges {
          node {
            _meta {
            firstPublicationDate
          }
            body {
              ... on PRISMIC_PageBodyNews {
                primary {
                  archiving_date
                  date_issued
                  image
                  teaser
                  text
                }
                type
              }
            }
            page_title
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`